<template>
  <div class="member-card">
    <v-avatar
      color="#dedede"
      class="member-card__avatar"
      darken
      size="40"
      elevation="1"
    >
      <img
        v-if="avatar"
        class="object-fit-cover"
        :src="avatar"
        :alt="fullName"
      />
      <v-icon v-else color="white" size="24">mdi-account</v-icon>
    </v-avatar>
    <div class="member-card__data">
      <div class="member-card__name">
        <div class="member-card__user-id">{{ userId }}</div>
        <div>{{ fullName }}</div>
      </div>
      <div v-if="isPersonOwner" class="member-card__captain">Капитан</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMemberCard",
  props: {
    team: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    avatar() {
      const { person } = this;
      if (!person || !person.person?.user) return "";
      return person.person.user.avatar;
    },
    userId() {
      return this.person?.person?.user?.id
        ? "#" + this.person.person.user.id
        : "";
    },
    fullName() {
      const { person } = this;
      if (!person || !person.person?.user) return "";
      return [
        person.person.user.last_name,
        person.person.user.first_name,
        person.person.user.middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
    isPersonOwner() {
      return this.team.owner === this.person?.person?.user?.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.member-card {
  width: 100%;
  display: flex;
  align-items: center;

  &__avatar {
    flex-grow: 0;
    font-size: 10px;
    color: #fff;
  }

  &__name {
    margin-right: 10px;
  }

  &__user-id {
    font-size: 0.8em;
    line-height: 1.2;
  }

  &__data {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
  }
}
</style>
