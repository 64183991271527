<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5 rm-dialog-title"
        >Команда: {{ (team && team.title) || "#" + teamId }}</v-card-title
      >
      <v-card-text v-if="team" class="pa-5 black--text text-body-1">
        <option-row v-if="team.description"
          ><span slot="title">Описание:</span>{{ team.description }}</option-row
        >
        <option-row v-if="createDate"
          ><span slot="title">Дата создания:</span>{{ createDate }}</option-row
        >
        <option-row
          ><span slot="title">{{ assignmentParticipation }}</span></option-row
        >
        <option-row v-if="team.created_by_assignment"
          ><span slot="title">Создана автораспределением</span></option-row
        >
        <option-row v-if="team.project"
          ><span slot="title">Проект:</span
          ><a
            href="#"
            class="link"
            @click.stop.prevent="$emit('openProjectDialog', team.project)"
          >
            <span v-if="pending"
              ><v-progress-circular
                size="18"
                width="2"
                indeterminate
                color="primary"
              ></v-progress-circular></span
            ><span v-else>{{
              project && project.title ? project.title : "#" + team.project
            }}</span>
          </a></option-row
        >

        <v-card v-if="team">
          <v-toolbar color="blue-grey darken-2 mt-5" dark dense flat>
            <v-toolbar-title class="white--text"
              >Участники команды</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="black--text text-body-1">
            <team-member-card
              v-for="person in sortedTeamPersons"
              :key="person.id"
              :team="team"
              :person="person"
              title="Перейти к карточке"
              class="team-card"
            />
          </v-card-text>
        </v-card>

        <v-card v-if="project">
          <v-toolbar color="blue-grey darken-2 mt-5" dark dense flat>
            <v-toolbar-title class="white--text">Проект</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="black--text text-body-1">
            <project-card
              :key="project.id"
              :project="project"
              class="contact-item"
            />
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="error" class="error--text">
        <p>{{ error }}</p>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn color="primary" @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { talentClient } from "@/api";
import ProjectCard from "@/components/contact/ProjectCard";
import TeamMemberCard from "@/components/contact/TeamMemberCard";

export default {
  name: "TeamDialog",
  components: { TeamMemberCard, ProjectCard },
  props: {
    value: Boolean,
    teamId: Number,
  },
  data() {
    return {
      team: null,
      project: null,
      pending: false,
      error: null,
    };
  },
  computed: {
    createDate() {
      if (!this.team.created_at) return;
      return dayjs(this.team.created_at).format("LLL");
    },
    assignmentParticipation() {
      return this.team.assignment_participation
        ? "Участвует в командном распределении"
        : "Не участвует в командном распределении";
    },
    talentId() {
      return +this.$route.params.id;
    },
    sortedTeamPersons() {
      if (!this.team?.persons?.length) return [];
      return [...this.team.persons].sort((a, b) => {
        return a.person?.user?.id === this.talentId
          ? -1
          : b.person?.user?.id === this.talentId
          ? 1
          : 0;
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && this.teamId) {
          this.init();
        }
      },
    },
  },
  methods: {
    async init() {
      this.pending = true;
      try {
        await this.getTeam();
        if (this.team?.project) {
          this.getProject();
        }
      } catch (error) {
        this.error = error.message;
        console.log("error", error);
      }
      this.pending = false;
    },
    async getTeam() {
      const { data } = await talentClient({
        method: "GET",
        url: `/users/${this.talentId}/teams/${this.teamId}/`,
      });
      if (data) {
        this.team = data;
      }
    },
    async getProject() {
      this.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/projects/${this.team.project}/`,
        });
        if (data) {
          this.project = data;
        }
      } catch (error) {
        console.log("error", error);
      }
      this.pending = false;
    },
    handleClose() {
      this.$emit("input", false);
      this.resetData();
    },
    resetData() {
      this.project = null;
      this.team = null;
      this.error = null;
      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-card + .team-card {
  padding-top: 10px;
  border-top: 1px solid #c0c0c0;
  margin-top: 10px;
}
</style>
